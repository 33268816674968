import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import illustration1 from "../../../assets/images/appImages/illustration1.png";
import monitor from "../../../assets/images/appImages/monitor.png";
import { TeamData } from "./data";
import logo from "../../../assets/images/appImages/logo.svg";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
}: {
  photo?: string;
  name: string;
  role?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper marrow_testure">
        <img src={logo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-0">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Mon histoire?" />
                <h3 className="custom-title">Aicha NDIAYE, Fondatrice</h3>
                <div className="custom-content">
                  Le 12 mai 2020, je suis devenue maman d’un petit garçon. Au
                  fil du temps, il grandissait et c’était un véritable plaisir
                  de le voir acquérir de nouvelles aptitudes physiques.
                  Seulement, par moment, je devais m’activer rapidement à la
                  maison (question de timing) et comme il était moteur, j’avais
                  peur pour sa sécurité. Je me suis mise à chercher un
                  porte-bébé pratique et surtout facile d’utilisation.
                  <br />
                  Je me suis alors souvenue de ma maman, qui, en tant que mère
                  au foyer, portait souvent mes frères sur le dos afin de
                  pouvoir s’activer plus rapidement à la maison et garder un œil
                  sur eux. BAPOO est le premier mot que chaque membre de ma
                  fratrie a prononcé. C’est une création de ma mère, pour
                  désigner le portage sur le dos. J’éprouvais le désir de porter
                  mon fils dans un climat de maternage, comme le faisait ma
                  maman.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Voyagez au sénégal avec bapoo" />
            <h3 className="custom-title">Découvrir le mbotou</h3>
            <div className="custom-content">
              Le mbotou est un <strong>porte-bébé dorsal</strong> utilisé au{" "}
              <strong>Sénégal</strong>. Il est un outil traditionnel car
              généralement la nouvelle maman le reçoit comme cadeau de la part
              de la tante paternelle de son enfant. Certaines familles lui
              vouent un pouvoir spirituel estimant qu’il protège le bébé des
              mauvais esprits.
              <br />
              <br />
              Le mbotou est aussi un outil pratique de la vie quotidienne.
              Facile à nouer, il permet de vaquer à ses occupations sans
              encombrement tout en permettant à son enfant de bénéficier de la
              chaleur parentale. Le Mbotou est un porte-bébé sécure utilisable
              dès que bébé sait tenir son coup tout seul et jusqu’à 15 kilos.
              <br />
              <br />
              Les Mbotous vendus par Bapoo regorgent des rayons du soleil du
              Sénégal et allient praticité et esthétique. Bapoo accompagne ses
              clients avec un service client réactif afin d’assurer une rapide
              prise en main du Mbotou.
              <br />
              <br />
              <strong>
                {" "}
                Bapoo propose aux personnes désireuses d’allier parentalité et
                vie quotidienne, le porte-bébé Mbotou qui permet de gagner en
                mobilité et d’avoir les mains libres.
              </strong>
            </div>
          </div>
        </div>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img src={illustration1} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Nos engagements?" />
                <div className="custom-content">
                  Soucieuse de{" "}
                  <strong>conserver l’origine culturelle du mbotou</strong>,
                  l’entreprise Bapoo a choisi de les faire coudre au Sénégal
                  afin d’en respecter l’esprit, l’expertise et la méthode de
                  fabrication qui a toujours été artisanale.
                  <br />
                  Par ailleurs, Bapoo privilégie les{" "}
                  <strong>
                    collaborations avec des femmes dans le but de participer à
                    leur autonomie financière
                  </strong>{" "}
                  car l’inclusion féminine est un véritable enjeu dans les pays
                  en développement. Au Sénégal, le chômage touche deux fois plus
                  de femmes que d’hommes. La fabrication des mbotous est donc
                  assurée par une dame qui a plus de{" "}
                  <strong>30 ans d’expérience</strong> dans le domaine de la
                  couture.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes nous?" />
            <h3 className="custom-title">Quelques adresses</h3>
            <div className="row pt-4">
              <div className="col-md-6">
                <div className="founder-card">
                  <span className="photo-wrapper">
                    <img src={monitor} alt="founder" />
                  </span>
                  <span className="name">Julia VARRIOT</span>
                  <span className="role">
                    <em>Monitrice de portage</em>
                  </span>
                  <span className="description">
                    Adresse : 6 résidence les jonquilles, 91140,
                    Villebon-sur-Yvette <br />
                    Téléphone : +33 6 89 24 39 75 <br />
                    Email : portetonlien@gmail.com
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="our-team">
                    <div className="container">
                        <CustomBeautyTitle 
                            title="Team"
                        />
                        <h3 className="custom-title">Notre équipe</h3>
                        <div className="row pt-4">
                        {!!TeamData &&
                           TeamData.map((member, key) => {
                            return(
                                <div className="col-md-3 card-wrapper">
                                    <TeamCard 
                                        photo={member.photo}
                                        name={member.name}
                                        role={member.role}
                                    />
                                </div>
                            )
                           })
                        }
                        </div>
                    </div>
                </div> */}
      </div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
