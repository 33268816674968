import React from "react";
import Fade from "react-reveal/Fade";
import Pp from "../../assets/images/appImages/Avatar.png";
import IconImg from "../../assets/images/icons/quote-up.svg";
import { BsFillStarFill } from "react-icons/bs";
import AliceCarousel from "react-alice-carousel";
import { responsiveI } from "./HomePage";

function Testimonial() {
  return (
    <div className="custom_carousel__wrapper">
      <AliceCarousel
        autoWidth={true}
        mouseTracking
        responsive={responsiveI}
        autoPlayStrategy="default"
        controlsStrategy="alternate"
        autoPlay={true}
        infinite={true}
        keyboardNavigation={false}
        autoPlayInterval={2000}
        disableDotsControls
      >
        {Data?.map((testimonial) => (
          <TestimonialItem item={testimonial} key={testimonial.id} />
        ))}
      </AliceCarousel>
    </div>
  );
}

export default Testimonial;

export const TestimonialItem = ({ item }: { item: any }) => {
  return (
    <Fade bottom cascade>
      <div className="container-testimonial-item">
        <div className="content-img-icon-testimonial">
          <img loading="lazy" src={item?.icon} alt="Auote" />
        </div>
        <div className="container-text-testimonial py-4">
          <p className="text-item-testimonial">{item?.text}</p>
        </div>
        <div className="content-footer-testimonial w-100">
          <div className="content-item-footer-testimonial">
            <p className="m-0 name-user-testimonial">{item?.name}</p>
          </div>
        </div>
      </div>
    </Fade>
  );
};

const Data = [
  {
    id: 1,
    text: "Je voulais juste vous faire un petit retour pour vous remercier encore une fois. Ma petite et moi l'adorons... En plus, c'est un véritable piège à dodo ! Encore merci!",
    icon: IconImg,
    name: "Elodie",
  },
  {
    id: 2,
    text: "Mon Mbotou de chez BAPOO - Portage Physiologique Je suis méga fan, j'adore ! J'aimais déjà beaucoup le pagne, mais je n'étais pas particulièrement à l'aise avec les nœuds devant, qui me faisaient un peu mal. Avec ce Mbotou, je suis en extase ! Même au magasin de bricolage, il permet de porter mon fils en deux temps, trois mouvements. Merci beaucoup!",
    icon: IconImg,
    name: "Jade",
  },
  {
    id: 3,
    text: "Merci pour le super Mbotou Maalikan",
    icon: IconImg,
    name: "Emma",
  },
  {
    id: 4,
    text: "Le Mbotou est hyper pratique pour accomplir les tâches du quotidien !",
    icon: IconImg,
    name: "Romane",
  },
  {
    id: 5,
    text: "J'ai découvert le portage de @bapoosenegal : maman comblée et bébé endormi en 10 minutes. Idéal aussi pour ma petite noisette, très curieuse de découvrir le monde.",
    icon: IconImg,
    name: "Camille",
  },

];
